import React from "react";

import Logo from "./Logo";
import Navigation from "./Navigation";
import Contact from "./Contact";

const Header = () => {
  return (
    <header id="start" className="absolute w-full z-10">
      <div className="container-normal justify-between items-center relative z-10 h-20 lg:h-[96px]">
        <Logo />
        <div className="flex">
          <Navigation />
          <Contact />
        </div>
      </div>
      <div className="top-0 right-0 lg:w-[185px] xl:w-[210px] 2xl:w-[300px] h-[96px] z-0"></div>
    </header>
  );
};

export default Header;

// import React from "react";
// import clsx from "clsx";
// import { MdSearch, MdMenu, MdClose } from "react-icons/md";
// import { menu } from "../constans/menu";
// import { Link } from "gatsby";
// import Logo from "./Logo";
// import ActionButton from "./buttons/ActionButton";
// import { SearchModalContext } from "../context/SearchModalContextProvider";

// const Header = () => {
//   const [isNavOpen, setIsNavOpen] = React.useState(false);
//   const { openSearchModal } = React.useContext(SearchModalContext);

//   const handleSearchModalOpen = () => {
//     openSearchModal();
//   };

//   const handleNavItemClick = () => {
//     if (isNavOpen) {
//       setIsNavOpen(false);
//     }
//   };

//   return (
//     <header className="relative w-full z-50 overflow-hidden bg-black-1 p-4 transition-colors duration-300 ease-in-out">
//       <div className="container mx-auto flex justify-between items-center">
//         <div className="logo">
//           <Logo />
//         </div>
//         <div className={clsx("nav__wrapper", isNavOpen && "open")}>
//           <div className="mobileIcon flex gap-4">
//             <div
//               className="searchIcon cursor-pointer"
//               onClick={handleSearchModalOpen}
//             >
//               <div className="searchIcon__wrapper rounded-md p-3 flex items-center justify-center bg-black-2">
//                 <MdSearch className="text-2xl" />
//               </div>
//             </div>
//             <ActionButton
//               className="mobileMenuBtn"
//               onClick={() => setIsNavOpen(true)}
//               onKeyDown={() => setIsNavOpen(true)}
//             >
//               <MdMenu className="text-2xl" />
//             </ActionButton>
//           </div>
//           {isNavOpen && (
//             <div
//               className="mobileNavBg fixed top-0 left-0 w-full h-full z-40 bg-black bg-opacity-50"
//               aria-label="close menu"
//               role="button"
//               tabIndex={0}
//               onClick={() => setIsNavOpen(false)}
//               onKeyDown={() => setIsNavOpen(false)}
//             ></div>
//           )}
//           <nav
//             className={`fixed top-0 right-0 w-4/5 bg-black-2 h-screen flex items-center justify-center transform transition-transform duration-300 ease-in-out ${isNavOpen ? "translate-x-0" : "translate-x-full"}`}
//           >
//             <ActionButton
//               className="mobileMenuCloseBtn text-crimson absolute top-4 right-4 border border-crimson"
//               onClick={() => setIsNavOpen(false)}
//               onKeyDown={() => setIsNavOpen(false)}
//             >
//               <MdClose />
//             </ActionButton>
//             <ul className="flex flex-col items-center gap-4 w-9/10">
//               {menu.map((item) => (
//                 <li key={item.path} className="w-full">
//                   <Link
//                     to={item.path}
//                     className="block w-full p-2 rounded-md hover:bg-black-1"
//                     onClick={handleNavItemClick}
//                   >
//                     {item.title}
//                   </Link>
//                 </li>
//               ))}
//               <li className="searchIcon hidden">
//                 <div
//                   className="searchIcon__wrapper rounded-md p-3 flex items-center justify-center"
//                   onClick={handleSearchModalOpen}
//                   onKeyDown={handleSearchModalOpen}
//                 >
//                   <MdSearch />
//                 </div>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;
