exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-author-list-js": () => import("./../../../src/templates/author-list.js" /* webpackChunkName: "component---src-templates-author-list-js" */),
  "component---src-templates-preview-js": () => import("./../../../src/templates/preview.js" /* webpackChunkName: "component---src-templates-preview-js" */),
  "component---src-templates-single-author-js": () => import("./../../../src/templates/single-author.js" /* webpackChunkName: "component---src-templates-single-author-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/single-category.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single-page.js" /* webpackChunkName: "component---src-templates-single-page-js" */)
}

