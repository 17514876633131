import React from "react";
import { useFlexSearch } from "react-use-flexsearch";
import {
  AuthorSearchResultItem,
  BlogSearchResultItem,
  CategorySearchResultItem,
} from "./SearchResultItem";
import ParagraphText from "../typography/ParagraphText";

const SearchResult = ({
  searchQuery,
  blogsIndexStore,
  categoriesIndexStore,
  authorsIndexStore,
}) => {
  const blogsResult = useFlexSearch(
    searchQuery,
    JSON.stringify(blogsIndexStore?.index || {}),
    blogsIndexStore?.store || {}
  );
  const categoriesResult = useFlexSearch(
    searchQuery,
    JSON.stringify(categoriesIndexStore?.index || {}),
    categoriesIndexStore?.store || {}
  );
  const authorsResult = useFlexSearch(
    searchQuery,
    JSON.stringify(authorsIndexStore?.index || {}),
    authorsIndexStore?.store || {}
  );

  if (
    (blogsResult?.length || 0) === 0 &&
    (categoriesResult?.length || 0) === 0 &&
    (authorsResult?.length || 0) === 0
  ) {
    return <ParagraphText>No Result Found.</ParagraphText>;
  }

  return (
    <div className="max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300">
      {blogsResult?.length > 0 && (
        <>
          <ParagraphText>Posty</ParagraphText>
          {blogsResult.map((result) => (
            <BlogSearchResultItem
              key={result?.id || Math.random()}
              blog={result}
            />
          ))}
        </>
      )}
      {categoriesResult?.length > 0 && (
        <>
          <ParagraphText>Kategorie</ParagraphText>
          {categoriesResult.map((result) => (
            <CategorySearchResultItem
              key={result?.id || Math.random()}
              category={result}
            />
          ))}
        </>
      )}
      {authorsResult?.length > 0 && (
        <>
          <ParagraphText>Autorzy</ParagraphText>
          {authorsResult.map((result) => (
            <AuthorSearchResultItem
              key={result?.id || Math.random()}
              author={result}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default SearchResult;
