import React from "react";

function ParagraphText({ children, ...props }) {
  return (
    <p className="font-inter text-grey text-xl leading-6 md:text-lg" {...props}>
      {children}
    </p>
  );
}

export default ParagraphText;
