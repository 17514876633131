import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => (
  <div className="logo flex items-center">
    <Link to="/">
      <StaticImage
        src="../images/konplan-logo-white.png"
        alt="logo"
        className="w-[140px] xl:w-[170px] h-auto object-contain"
        layout="constrained"
        placeholder="none"
      />
    </Link>
  </div>
);

export default Logo;
