import React from "react";

const ActionButton = ({ children, ...rest }) => {
  return (
    <div
      className="cursor-pointer border-2 border-primary p-2 rounded-lg text-primary flex items-center justify-center transition-colors duration-300 ease-in-out hover:bg-primary hover:text-white-1"
      role="button"
      tabIndex={0}
      {...rest}
    >
      {children}
      <style jsx>{`
        .border-primary {
          border-color: var(--primary);
        }
        .text-primary {
          color: var(--primary);
        }
        .hover\:bg-primary:hover {
          background-color: var(--primary);
        }
        .hover\:text-white-1:hover {
          color: var(--white-1);
        }
      `}</style>
    </div>
  );
};

export default ActionButton;
