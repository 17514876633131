import React from "react";
import { FaFacebookF } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";
import { IoLogoYoutube } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { useSearchModal } from "../context/SearchModalContextProvider"; // Zaimportuj useSearchModal

const Contact = () => {
  const { openSearchModal } = useSearchModal(); // Uzyskaj dostęp do funkcji otwierającej modal

  return (
    <div className="contact hidden lg:flex justify-center items-center p-4 rounded-l-full">
      {/* Facebook */}
      <a
        href="https://www.facebook.com/konplanpl/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      >
        <FaFacebookF className="bg-transparent rounded-md border border-white border-solid p-[2.5px] text-[1.5rem] ml-2 text-white" />
      </a>

      {/* Instagram */}
      {/* <a
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <PiInstagramLogoFill className="bg-transparent rounded-md border border-white border-solid p-[2.5px] text-[1.5rem] ml-2 text-white" />
      </a> */}

      {/* YouTube */}
      {/* <a
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="YouTube"
      >
        <IoLogoYoutube className="bg-transparent rounded-md border border-white border-solid p-[2.5px] text-[1.5rem] ml-2 text-white" />
      </a> */}

      {/* Twitter (X) */}
      <a
        href="https://x.com/i/flow/login?redirect_after_login=%2Fkonplankonin"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter"
      >
        <FaXTwitter className="bg-transparent rounded-md border border-white border-solid p-[2.5px] text-[1.5rem] ml-2 text-white" />
      </a>

      {/* LinkedIn */}
      <a
        href="https://pl.linkedin.com/in/konplan-konin-0134bb14b"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <FaLinkedinIn className="bg-transparent rounded-md border border-white border-solid p-[2.5px] text-[1.5rem] ml-2 text-white" />
      </a>

      {/* Przycisk wyszukiwania, który otworzy modal */}
      <FaSearch
        onClick={openSearchModal} // Wywołanie funkcji otwierającej modal
        className="bg-transparent rounded-md border border-white border-solid p-[2.5px] text-[1.5rem] ml-2 text-white cursor-pointer"
      />
    </div>
  );
};

export default Contact;
