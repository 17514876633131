import React from "react";

export const SectionTitle = ({ children, tag = "h2", ...props }) => (
  <h2
    className="text-3xl font-semibold text-primary md:text-2xl"
    as={tag}
    {...props}
  >
    {children}
  </h2>
);

export const Title = ({ children, tag = "h2", ...props }) => (
  <h2 className="text-2xl font-semibold md:text-xl" as={tag} {...props}>
    {children}
  </h2>
);
