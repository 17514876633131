import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ParagraphText from "../typography/ParagraphText";
import { Title } from "../typography/Title";
import { SearchModalContext } from "../../context/SearchModalContextProvider";
import SearchDynamicLink from "./SearchDynamicLink"; // Dodany import

// Blog Item
export function BlogSearchResultItem({ blog }) {
  const { closeSearchModal } = React.useContext(SearchModalContext);

  return (
    <SearchDynamicLink
      item={blog}
      className="flex items-center bg-black-2 p-2 gap-4 w-full"
      onClick={closeSearchModal}
    >
      {blog?.coverImage?.asset?.gatsbyImageData && (
        <GatsbyImage
          image={blog.coverImage.asset.gatsbyImageData}
          alt={blog?.coverImage?.alt || "Blog Cover"}
          className="w-12 h-10 rounded"
        />
      )}
      <div>
        <Title className="text-xl">{blog?.title || "Brak tytułu"}</Title>
        {blog?.publishDate && (
          <ParagraphText className="text-lg text-grey">
            {new Date(blog.publishDate).toLocaleDateString()}
          </ParagraphText>
        )}
      </div>
    </SearchDynamicLink>
  );
}

// Category Item
export function CategorySearchResultItem({ category }) {
  const { closeSearchModal } = React.useContext(SearchModalContext);

  return (
    <SearchDynamicLink
      item={category}
      className="flex items-center bg-black-2 p-2 gap-4 w-full"
      onClick={closeSearchModal}
    >
      <Title className="text-xl">{category?.title || "Brak tytułu"}</Title>
    </SearchDynamicLink>
  );
}

// Author Item
export function AuthorSearchResultItem({ author }) {
  const { closeSearchModal } = React.useContext(SearchModalContext);

  return (
    <SearchDynamicLink
      item={author}
      className="flex items-center bg-black-2 p-2 gap-4 w-full"
      onClick={closeSearchModal}
    >
      {author?.profileImage?.asset?.gatsbyImageData && (
        <GatsbyImage
          image={author.profileImage.asset.gatsbyImageData}
          alt={author?.profileImage?.alt || "Author Profile"}
          className="w-8 h-8 rounded-full"
        />
      )}
      <Title className="text-xl">{author?.name || "Brak imienia"}</Title>
    </SearchDynamicLink>
  );
}
