import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <footer className="footer bg-[#fafafa] text-black py-14">
      <div className="container-normal mx-auto flex flex-col lg:flex-row justify-between px-6">
        {/* Logo i godziny pracy */}
        <div className="footer-logo w-3/6 2xl:w-4/6 flex flex-col items-start mb-6 lg:mb-0">
          <StaticImage
            src="../images/konplan-logo-color.png"
            alt="Logo"
            className="w-[150px] h-auto object-contain mb-3"
          />
          <p className="font-bold mb-2">Godziny pracy Pn-Pt (7.00 - 15.00)</p>
          <div className="w-full justify-start text-gray-700">
            <p className="mb-2">
              Copyright © 2024. Wszelkie prawa zastrzeżone.
            </p>
            <p className="text-gray-700">
              Realizacja projektu{" "}
              <a className="hover:underline" href="https://webkon.eu/">
                Webkon
              </a>
            </p>
          </div>
        </div>

        {/* Informacje o firmie */}
        <div className="flex flex-col lg:flex-row w-full lg:w-4/6 xl:w-3/6 2xl:w-3/6 justify-between relative xl:before:content-[''] xl:before:absolute xl:before:left-0 xl:before:top-0 xl:before:bottom-0 xl:before:w-[3px] xl:before:bg-[#ffe0cb] md:before:-ml-8">
          {/* Dane firmy */}
          <div className="footer-info w-full lg:w-2/6 xl:w-4/6 mb-6 lg:mb-0 pr-4">
            <h2 className="text-lg font-bold mb-2">PPHU Konplan</h2>
            <div className="text text-gray-700">
              <p>
                Kolonia Kraśnica 73
                <br />
                62-590 Golina
                <br />
                NIP: 6651596592
                <br />
                REGON: 310299944
              </p>
            </div>
          </div>
          {/* Kontakt - Biuro i siedziba */}
          <div className="footer-office w-full lg:w-2/6 xl:w-1/2">
            <h2 className="text-lg font-bold mb-2">Biuro i siedziba firmy</h2>
            <div className="text text-gray-700">
              <p>
                Kolonia Kraśnica 73
                <br />
                62-590 Golina
                <br />
                Email:{" "}
                <a
                  href="mailto:info@konplan.pl"
                  id="footer-email"
                  className="text-gray-700 font-bold transition-colors hover:text-[#a36c47] hover:bg-transparent duration-700"
                >
                  info@konplan.pl
                </a>
                <br />
                Telefon:{" "}
                <a
                  href="tel:+48509244152"
                  id="footer-phone"
                  className="text-gray-700 font-bold transition-colors hover:text-[#a36c47] hover:bg-transparent duration-700"
                >
                  +48 509 244 152
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
