import React from "react";
import { FiSearch } from "react-icons/fi";

function SearchField({ value, setValue, onFocus }) {
  return (
    <label className="relative w-full">
      <input
        type="text"
        placeholder="Szukaj"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={onFocus && onFocus}
        className="w-full text-xl p-4 border-0 rounded-md text-darkBlue focus:outline-none focus:ring-2 focus:ring-secondary"
      />
      <FiSearch className="absolute w-5 h-5 right-2 top-1/2 transform -translate-y-1/2 text-primary" />
    </label>
  );
}

export default SearchField;
