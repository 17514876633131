import React from "react";
import { Link } from "gatsby";

const buildSlug = (category) => {
  console.log("buildSlug category: ", category);

  if (!category) return "";

  let slugs = [];
  let currentCategory = category;

  while (currentCategory) {
    const slugPart =
      currentCategory.slug?.current || currentCategory._rawSlug?.current;

    console.log("Current category:", currentCategory); // Debug
    console.log("Slug part:", slugPart); // Debug

    if (slugPart) {
      slugs.unshift(slugPart);
    }

    currentCategory = currentCategory.parentCategory;
  }

  console.log("Final slugs:", slugs.join("/")); // Debug
  return slugs.join("/");
};

const generateSlug = (item) => {
  console.log("generateSlug item: ", item);

  if (!item) {
    console.error("Brak danych dla generowania slugu:", item);
    return "/";
  }

  const itemType = item._type || item.internal?._type;
  console.log("itemType: ", itemType);

  switch (itemType) {
    case "blog.category": {
      const categorySlug = buildSlug(item);
      console.log("blog.category categorySlug", categorySlug);

      return categorySlug ? `/${categorySlug}` : "/";
    }
    case "blog.post": {
      const categorySlug = buildSlug(item.categories?.[0]);
      console.log("blog.post categorySlug", categorySlug);

      const postSlug = item.slug?.current || "";
      if (!categorySlug || !postSlug) {
        console.error(
          "Nieprawidłowe dane do generowania slugu dla postu:",
          item
        );
        return "/";
      }
      return `/${categorySlug}/${postSlug}`;
    }
    case "blog.author": {
      const authorSlug = item.slug?.current || "";
      if (!authorSlug) {
        console.error("Brak slug dla autora:", item);
        return "/";
      }
      return `/authors/${authorSlug}`;
    }
    default:
      console.error("Nieobsługiwany typ danych:", itemType, item);
      return "/";
  }
};

const SearchDynamicLink = ({ item, children, className, onClick }) => {
  if (!item) return null;

  const href = generateSlug(item);

  return (
    <Link to={href} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};

export default SearchDynamicLink;
