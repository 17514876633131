import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { MdClose } from "react-icons/md";
import { SearchModalContext } from "../../context/SearchModalContextProvider";
import ActionButton from "../buttons/ActionButton";
import SearchField from "./SearchField";
import SearchResult from "./SearchResult";
import axios from "axios";

const query = graphql`
  {
    localSearchBlogs {
      publicStoreURL
      publicIndexURL
    }
    localSearchCategories {
      publicStoreURL
      publicIndexURL
    }
    localSearchAuthors {
      publicStoreURL
      publicIndexURL
    }
  }
`;

const Search = () => {
  const { isSearchModalOpen, closeSearchModal } =
    React.useContext(SearchModalContext);
  const [searchQuery, setSearchQuery] = React.useState("");
  const data = useStaticQuery(query);
  const [blogsIndexStore, setBlogsIndexStore] = React.useState(null);
  const [categoriesIndexStore, setCategoriesIndexStore] = React.useState(null);
  const [authorsIndexStore, setAuthorsIndexStore] = React.useState(null);

  const {
    publicStoreURL: blogsPublicStoreURL = "",
    publicIndexURL: blogsPublicIndexURL = "",
  } = data?.localSearchBlogs || {};
  const {
    publicStoreURL: categoriesPublicStoreURL = "",
    publicIndexURL: categoriesPublicIndexURL = "",
  } = data?.localSearchCategories || {};
  const {
    publicStoreURL: authorsPublicStoreURL = "",
    publicIndexURL: authorsPublicIndexURL = "",
  } = data?.localSearchAuthors || {};

  const handleOnFocus = async () => {
    if (blogsIndexStore && categoriesIndexStore && authorsIndexStore) return;

    try {
      const [
        { data: blogsIndex },
        { data: blogsStore },
        { data: categoriesIndex },
        { data: categoriesStore },
        { data: authorsIndex },
        { data: authorsStore },
      ] = await Promise.all([
        axios.get(`${blogsPublicIndexURL}`),
        axios.get(`${blogsPublicStoreURL}`),
        axios.get(`${categoriesPublicIndexURL}`),
        axios.get(`${categoriesPublicStoreURL}`),
        axios.get(`${authorsPublicIndexURL}`),
        axios.get(`${authorsPublicStoreURL}`),
      ]);

      setBlogsIndexStore({
        index: blogsIndex || {},
        store: blogsStore || {},
      });
      setCategoriesIndexStore({
        index: categoriesIndex || {},
        store: categoriesStore || {},
      });
      setAuthorsIndexStore({
        index: authorsIndex || {},
        store: authorsStore || {},
      });
    } catch (error) {
      console.error("Error fetching search index data:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeSearchModal();
    }
  };

  React.useEffect(() => {
    if (isSearchModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearchModalOpen]);

  if (!isSearchModalOpen) return null;

  return (
    <div className="fixed inset-0 z-50 min-h-screen bg-black bg-opacity-75 flex items-center justify-center">
      <div className="absolute top-24 left-1/2 transform -translate-x-1/2 w-11/12 max-w-md bg-gray-800 p-8 rounded-lg flex flex-col items-center">
        {/* Przycisk zamknięcia */}
        <button
          onClick={closeSearchModal}
          className="absolute top-0 right-[-15px] transform -translate-y-1/2 border border-crimson text-crimson hover:bg-crimson bg-white hover:bg-gray-300 rounded-full"
          aria-label="close modal"
        >
          <MdClose className="w-8 h-8 text-black" />
        </button>
        <h1 className="text-white text-4xl mb-4">Szukaj</h1>
        <SearchField
          value={searchQuery}
          setValue={setSearchQuery}
          onFocus={handleOnFocus}
        />
        {searchQuery &&
          blogsIndexStore &&
          categoriesIndexStore &&
          authorsIndexStore && (
            <div className="mt-4 w-full flex flex-col gap-4 overflow-y-auto text-white">
              <SearchResult
                searchQuery={searchQuery}
                blogsIndexStore={blogsIndexStore}
                categoriesIndexStore={categoriesIndexStore}
                authorsIndexStore={authorsIndexStore}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default Search;
