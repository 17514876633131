import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Logo from "../images/konplan-logo-color.png";

import { SearchModalContextProvider } from "../context/SearchModalContextProvider";
import Search from "./search/SearchModal";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      sanitySite {
        siteIsOff
        disabledMessage
        disabledPhone
        disabledEmail
      }
      sanitySite {
        gtmId
      }
    }
  `);
  const { siteIsOff, disabledMessage, disabledPhone, disabledEmail } =
    data.sanitySite;

  // Jeśli strona jest wyłączona, renderujemy komunikat o wyłączeniu
  if (siteIsOff) {
    return (
      <div className="h-screen w-full flex items-center justify-center bg-gray-200">
        <div className="text-center">
          {/* Wyświetlamy logo */}
          <img
            src={Logo} // Ścieżka do logo w folderze static
            alt="Konplan Logo"
            className="mx-auto mb-6" // Dodajemy klasy Tailwind do wyśrodkowania logo
            width={200} // Możesz dostosować szerokość logo
          />
          <h1 className="text-3xl font-bold">{disabledMessage}</h1>
          <p className="mt-4 text-lg">
            Strona jest chwilowo niedostępna. Prosimy o cierpliwość.
          </p>
          <div className="mt-6">
            <p className="text-lg">Kontakt:</p>
            <p className="mt-2 text-xl">
              <strong>Telefon:</strong> {disabledPhone}
            </p>
            <p className="mt-2 text-xl">
              <strong>Email:</strong>{" "}
              <a href={`mailto:${disabledEmail}`} className="text-blue-500">
                {disabledEmail}
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (children.key === "/404.html") {
    return (
      <SearchModalContextProvider>
        <GlobalStyles />
        <Search />
        <Header />
        <div>{children}</div>
      </SearchModalContextProvider>
    );
  }

  return (
    <SearchModalContextProvider>
      <Search />
      <Header />
      <div>{children}</div>
      <Footer />
    </SearchModalContextProvider>
  );
};

export default Layout;
