import React from "react";

const SearchModalContext = React.createContext();

const SearchModalContextProvider = ({ children }) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = React.useState(false);
  const initialValue = React.useMemo(
    () => ({
      isSearchModalOpen,
      openSearchModal: () => {
        setIsSearchModalOpen(true);
      },
      closeSearchModal: () => {
        setIsSearchModalOpen(false);
      },
    }),
    [isSearchModalOpen]
  );

  return (
    <SearchModalContext.Provider value={initialValue}>
      {children}
    </SearchModalContext.Provider>
  );
};

// Hook do pobrania wartości z kontekstu
const useSearchModal = () => {
  const context = React.useContext(SearchModalContext);
  if (!context) {
    throw new Error(
      "useSearchModal must be used within a SearchModalContextProvider"
    );
  }
  return context;
};

export { SearchModalContext, SearchModalContextProvider, useSearchModal };
