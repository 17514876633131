import React, { useState, useEffect } from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import { FaBars } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { TfiArrowCircleDown, TfiArrowCircleUp } from "react-icons/tfi";

const buildSlug = (category) => {
  let slugs = [];
  let currentCategory = category;

  while (currentCategory) {
    const slugPart =
      currentCategory.slug?.current || currentCategory._rawSlug?.current;
    if (slugPart) {
      slugs.unshift(slugPart); // Dodajemy slug na początek tablicy
    }
    currentCategory = currentCategory.parentCategory; // Przechodzimy do parentCategory
  }

  return slugs.join("/"); // Łączymy slugi w całość
};

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  // Funkcja do przełączania submenu
  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allSanityBlogCategory {
            nodes {
              id
              title
              description {
                children {
                  text
                }
              }
              slug {
                current
              }
              parentCategory {
                id
                slug {
                  current
                }
              }
              coverImage {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
          sanityNavigation {
            menuType
            items {
              ... on SanityLinkList {
                _key
                _type
                link {
                  label
                  params
                  external
                  internal {
                    ... on SanityBlogPost {
                      id
                      title
                      _type
                      _rawSlug
                      categories {
                        title
                        _rawSlug

                        parentCategory {
                          _id
                          title
                          slug {
                            current
                          }
                          parentCategory {
                            _id
                            title
                            slug {
                              current
                            }
                            parentCategory {
                              _id
                              title
                              slug {
                                current
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on SanityBlogCategory {
                      id
                      title
                      _type
                      _rawSlug

                      parentCategory {
                        _id
                        title
                        slug {
                          current
                        }
                        parentCategory {
                          _id
                          title
                          slug {
                            current
                          }
                          parentCategory {
                            _id
                            title
                            slug {
                              current
                            }
                          }
                        }
                      }
                    }
                    ... on SanityPage {
                      id
                      title
                      _type
                      _rawSlug
                    }
                  }
                }
                links {
                  external
                  label
                  params
                  internal {
                    ... on SanityBlogPost {
                      id
                      title
                      _type
                      _rawSlug
                      categories {
                        title
                        _rawSlug

                        parentCategory {
                          _id
                          title
                          slug {
                            current
                          }
                          parentCategory {
                            _id
                            title
                            slug {
                              current
                            }
                            parentCategory {
                              _id
                              title
                              slug {
                                current
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on SanityBlogCategory {
                      id
                      title
                      _type
                      _rawSlug

                      parentCategory {
                        _id
                        title
                        slug {
                          current
                        }
                        parentCategory {
                          _id
                          title
                          slug {
                            current
                          }
                          parentCategory {
                            _id
                            title
                            slug {
                              current
                            }
                          }
                        }
                      }
                    }
                    ... on SanityPage {
                      id
                      title
                      _rawSlug
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const navigationItems = data.sanityNavigation?.items || [];
        const menuType = data.sanityNavigation?.menuType === "main";

        if (!navigationItems.length) {
          return null;
        }

        return (
          <>
            <button
              className="cursor-pointer lg:hidden block text-white text-3xl"
              onClick={() => {
                setIsMenuOpen((prevState) => !prevState);
              }}
              aria-label="Otwórz menu"
            >
              <FaBars />
            </button>

            {menuType && (
              <>
                {/* Navigation for Larger Screens */}
                <nav className="navigation lg:flex hidden flex-col lg:flex-row justify-center items-center mr-3">
                  <ul className="flex flex-col h-full lg:flex-row space-y-5 lg:space-y-0 lg:space-x-3 xl:space-x-5 text-[1.25em] lg:text-[1em] xl:text-[1.15em] 2xl:text-[1.25em] font-normal text-white tracking-wide">
                    {navigationItems.map((item, index) => {
                      // Sprawdzamy typ linku przed renderowaniem
                      let linkType = "";
                      let slug = "";

                      if (item.link?.internal) {
                        const internalLink = item.link.internal;
                        linkType = internalLink._type; // Określamy typ linku

                        // Generowanie sluga na podstawie typu
                        switch (linkType) {
                          case "blog.category":
                            slug = buildSlug(internalLink); // Generujemy slug za pomocą `buildSlug`
                            break;

                          case "blog.post":
                            slug = `${buildSlug(internalLink.categories?.[0])}/${internalLink._rawSlug?.current}`;
                            break;

                          case "page":
                            slug = internalLink._rawSlug?.current || "/";
                            break;

                          default:
                            slug = "/"; // W przypadku nieznanego typu nie generujemy sluga
                            break;
                        }
                      }

                      // Renderowanie linku po sprawdzeniu typu
                      if (item._type === "link-list") {
                        return (
                          <li key={item._key} className="relative group flex">
                            {item.link ? (
                              item.link.internal ? (
                                <Link
                                  to={`/${slug}`}
                                  className="hover:underline flex items-center" // Dodano flex i items-center
                                >
                                  {item.link.label}
                                  {item.links?.length > 0 && (
                                    <IoMdArrowDropdown className="ml-1 mt-1 w-3 lg:w-5 h-3 lg:h-5" />
                                  )}
                                  {/* Dodano margines do strzałki */}
                                </Link>
                              ) : item.link.external ? (
                                <a
                                  href={item.link.external}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="hover:underline flex items-center"
                                >
                                  {item.link.label}
                                  {item.links?.length > 0 && (
                                    <IoMdArrowDropdown className="ml-1 mt-1 w-3 lg:w-5 h-3 lg:h-5" />
                                  )}
                                </a>
                              ) : item.link.params ? (
                                <a
                                  href={`${item.link.params}`}
                                  className="hover:underline flex items-center"
                                >
                                  {item.link.label}
                                  {item.links?.length > 0 && (
                                    <IoMdArrowDropdown className="ml-1 mt-1 w-3 lg:w-5 h-3 lg:h-5" />
                                  )}
                                </a>
                              ) : (
                                <span className="hover:underline flex items-center">
                                  {item.link.label}
                                </span>
                              )
                            ) : (
                              <span className="hover:underline flex items-center">
                                {item.link.label}
                              </span>
                            )}

                            {item.links?.length > 0 && (
                              <ul className="absolute top-12 hidden group-hover:block bg-gray-100 text-black shadow-lg pt-2 pb-3 w-max text-[1.20rem] lg:text-[.95rem] xl:text-[1.10rem] 2xl:text-[1.2rem]">
                                {item.links.map((subItem) => {
                                  let subSlug = "";

                                  // Sprawdzamy, czy subItem ma internal
                                  if (subItem.internal) {
                                    const internalSubLink = subItem.internal;
                                    const subLinkType = internalSubLink._type;

                                    // Generowanie sluga na podstawie typu subItem
                                    switch (subLinkType) {
                                      case "blog.category":
                                        subSlug = buildSlug(internalSubLink); // Wykorzystaj `buildSlug`
                                        break;

                                      case "blog.post":
                                        subSlug = `${buildSlug(internalSubLink.categories?.[0])}/${internalSubLink._rawSlug?.current}`;
                                        break;

                                      case "page":
                                        // Obsługa sluga dla typu `page`
                                        subSlug =
                                          internalSubLink._rawSlug?.current ||
                                          "/"; // Fallback na pusty string, jeśli brak danych
                                        break;

                                      default:
                                        subSlug = "/"; // W przypadku nieznanego typu nie generujemy sluga
                                        break;
                                    }
                                  }

                                  return (
                                    <li
                                      key={
                                        subItem.internal?.id || subItem.label
                                      }
                                      className="w-full"
                                    >
                                      {subItem.internal ? (
                                        <Link
                                          to={`/${subSlug}`}
                                          className="block px-5 py-[2px] hover:bg-gray-200 w-full"
                                        >
                                          {subItem.label}
                                        </Link>
                                      ) : subItem.external ? (
                                        <a
                                          href={subItem.external}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="block px-5 py-[2px] hover:bg-gray-200 w-full"
                                        >
                                          {subItem.label}
                                        </a>
                                      ) : subItem.params ? (
                                        <a
                                          href={`#${subItem.params}`}
                                          className="block px-5 py-[2px] hover:bg-gray-200 w-full"
                                        >
                                          {subItem.label}
                                        </a>
                                      ) : (
                                        <span className="block px-5 py-2 w-full">
                                          {subItem.label}
                                        </span>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </li>
                        );
                      }

                      return null;
                    })}
                  </ul>
                </nav>

                {/* Mobile Menu */}
                <nav
                  className={`navigation ${isMenuOpen ? "flex" : "hidden"} lg:hidden flex-col items-center absolute top-20 left-0 bg-white w-full z-20`}
                >
                  <ul className="z-1000 relative flex w-full flex-col space-y-3 text-lg font-semibold text-black p-7">
                    {navigationItems.map((item, index) => {
                      let linkType = "";
                      let slug = "";

                      // Sprawdzamy typ linku przed renderowaniem
                      if (item.link?.internal) {
                        const internalLink = item.link.internal;
                        linkType = internalLink._type; // Określamy typ linku

                        // Generowanie sluga na podstawie typu
                        switch (linkType) {
                          case "blog.category":
                            slug = buildSlug(internalLink);
                            break;
                          case "blog.post":
                            slug = `${buildSlug(internalLink.categories?.[0])}/${internalLink._rawSlug?.current}`;
                            break;
                          case "page":
                            slug = internalLink._rawSlug?.current || "/";
                            break;
                          default:
                            slug = "/";
                            break;
                        }
                      }

                      if (item._type === "link-list") {
                        return (
                          <li
                            key={item._key}
                            className="relative flex justify-between"
                          >
                            {item.link ? (
                              item.link.internal ? (
                                <Link
                                  to={`/${slug}`}
                                  className="hover:underline"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  {item.link.label}
                                </Link>
                              ) : item.link.external ? (
                                <a
                                  href={item.link.external}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="hover:underline"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  {item.link.label}
                                </a>
                              ) : item.link.params ? (
                                <a
                                  href={`#${item.link.params}`}
                                  className="hover:underline"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  {item.link.label}
                                </a>
                              ) : (
                                <span className="block">{item.link.label}</span>
                              )
                            ) : (
                              <span className="block">{item.link.label}</span>
                            )}

                            {item.links?.length > 0 && (
                              <button
                                onClick={() => toggleSubMenu(index)}
                                className="ml-2 text-2xl"
                                aria-label="submenu switch"
                              >
                                {activeSubMenu === index ? (
                                  <TfiArrowCircleUp />
                                ) : (
                                  <TfiArrowCircleDown />
                                )}
                              </button>
                            )}

                            {item.links?.length > 0 &&
                              activeSubMenu === index && (
                                <ul
                                  className={`absolute z-10 top-full left-0 bg-gray-100 text-black shadow-lg pt-2 pb-3 w-full text-[1.20rem] lg:text-[.95rem] xl:text-[1.10rem] 2xl:text-[1.2rem] ${
                                    activeSubMenu === index ? "block" : "hidden"
                                  }`}
                                >
                                  {item.links.map((subItem) => {
                                    let subSlug = "";

                                    if (subItem.internal) {
                                      const internalSubLink = subItem.internal;
                                      const subLinkType = internalSubLink._type;

                                      switch (subLinkType) {
                                        case "blog.category":
                                          subSlug = buildSlug(internalSubLink);
                                          break;
                                        case "blog.post":
                                          subSlug = `${buildSlug(internalSubLink.categories?.[0])}/${internalSubLink._rawSlug?.current}`;
                                          break;
                                        case "page":
                                          subSlug =
                                            internalSubLink._rawSlug?.current ||
                                            "/";
                                          break;
                                        default:
                                          subSlug = "/";
                                          break;
                                      }
                                    }

                                    return (
                                      <li
                                        key={
                                          subItem.internal?.id || subItem.label
                                        }
                                        className="w-full"
                                      >
                                        {subItem.internal ? (
                                          <Link
                                            to={`/${subSlug}`}
                                            className="block px-5 py-[4px] hover:bg-gray-200 w-full hover:underline"
                                            onClick={() => {
                                              setIsMenuOpen(false);
                                              setActiveSubMenu(false);
                                            }}
                                          >
                                            {subItem.label}
                                          </Link>
                                        ) : subItem.external ? (
                                          <a
                                            href={subItem.external}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="block px-5 py-[4px] hover:bg-gray-200 w-full hover:underline"
                                            onClick={() => {
                                              setIsMenuOpen(false);
                                              setActiveSubMenu(false);
                                            }}
                                          >
                                            {subItem.label}
                                          </a>
                                        ) : subItem.params ? (
                                          <a
                                            href={`#${subItem.params}`}
                                            className="block px-5 py-[4px] hover:bg-gray-200 w-full hover:underline"
                                            onClick={() => {
                                              setIsMenuOpen(false);
                                              setActiveSubMenu(false);
                                            }}
                                          >
                                            {subItem.label}
                                          </a>
                                        ) : (
                                          <span className="block px-5 py-2 w-full hover:underline">
                                            {subItem.label}
                                          </span>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                          </li>
                        );
                      }

                      return null;
                    })}
                  </ul>
                </nav>
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default Navigation;
